import React, { useEffect } from 'react';
import './Toast.css';

interface ToastProps {
    message: string;
    duration?: number;
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 5000, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div 
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                padding: '20px 40px',
                borderRadius: '8px',
                zIndex: 1000,
                border: '2px solid red',
                animation: 'blink 1s step-end infinite',
            }}
        >
            {message}
        </div>
    );
};

export default Toast; 