import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import api from '../utils/ApiClient';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('UT03');
  const navigate = useNavigate();

  const handleRegister = async () => {
    // Basic validation
    if (!username || !password || !confirmPassword) {
      alert('请填写所有字段');
      return;
    }

    if (password !== confirmPassword) {
      alert('两次输入的密码不一致');
      return;
    }

    try {
        console.log('Current origin:', window.location.origin);
        const response = await api.post('/register', {
        username,
        password,
        userType,
      });

      console.log(response);
      if (response.status === 200) {
        alert('注册成功');
        navigate('/login');
      } else {
        alert(response.data.message || '请稍后重试');
      }
    } catch (error) {
      alert('注册请求失败，请稍后重试');
      console.error('Register error:', {
        error
      });
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h1 className="title">注册</h1>
        
        <input
          className="input"
          type="text"
          placeholder="用户名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoCapitalize="none"
        />
        
        <input
          className="input"
          type="password"
          placeholder="密码"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <input
          className="input"
          type="password"
          placeholder="确认密码"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        
        <div className="radio-group" style={{ margin: '10px 0' }}>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              name="userType"
              value="UT02"
              checked={userType === 'UT02'}
              onChange={(e) => setUserType(e.target.value)}
            />
            老师
          </label>
          <label>
            <input
              type="radio"
              name="userType"
              value="UT03"
              checked={userType === 'UT03'}
              onChange={(e) => setUserType(e.target.value)}
            />
            学生
          </label>
        </div>
        
        <button
          className="register-button"
          onClick={handleRegister}
        >
          注册
        </button>

        <button
          className="login-link"
          onClick={() => navigate('/login')}
        >
          已有账号？立即登录
        </button>
      </div>
    </div>
  );
}

export default Register;

