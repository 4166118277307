import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { connect, disconnect } from '../store/slices/websocketSlice';
import api from '../utils/ApiClient';
import './ConnectionPrep.css';

const ConnectionPrep = () => {
    const location = useLocation();
    const { roomId, type } = location.state || {};
    const { userId } = useAppSelector(state => state.user);
    const navigate = useNavigate();
    const { status, error } = useAppSelector(state => state.websocket);
    const dispatch = useAppDispatch();

    const pushTeacher = () => {
        if (type === 'create-room') {
            api.post('/teachers/teacherUp', { 
                userId, 
                roomId 
            }).then(response => console.log('Room created:', response));
        }
    };

    useEffect(() => {
        if (!roomId || !userId) {
            alert('连接参数错误'); 
            return;
        }
        dispatch(connect({
            type,
            roomId: roomId || '',
            userId: userId || '',
            date: new Date().toISOString()
        }));

        return () => {
        };
    }, [dispatch, roomId, userId, type]);

    useEffect(() => {
        if (status === 'connected') {
            pushTeacher();
            setTimeout(() => {
                navigate(`/room/${roomId}?userId=${userId}&type=${type}`);
            }, 1000);
        }
    }, [status, roomId, userId]);

    const handleRetry = () => {
        dispatch(connect({
            type,
            roomId: roomId || '',
            userId: userId || '',
            date: new Date().toISOString()
        }));
    };

    const renderConnectionStatus = () => {
        const statusComponents = {
            disconnected: (
                <>
                    <div className="error-icon">✗</div>
                    <h2>房间创建失败</h2>
                    <p>{error || '连接被关闭，请检查网络后重试'}</p>
                </>
            ),
            connecting: (
                <>
                    <div className="loading-spinner"></div>
                    <h2>正在创建房间...</h2>
                    <p>请稍候，正在建立连接</p>
                </>
            ),
            connected: (
                <>
                    <div className="success-icon">✓</div>
                    <h2>连接成功！</h2>
                    <p>正在进入房间...</p>
                </>
            ),
            failed: (
                <>
                    <div className="error-icon">✗</div>
                    <h2>房间创建失败</h2>
                    <p>{error || '连接失败，请检查网络后重试'}</p>
                    <div className="action-buttons">
                        <button onClick={handleRetry}>重试</button>
                        <button onClick={() => navigate('/')}>返回首页</button>
                    </div>
                </>
            )
        };

        return statusComponents[status] || statusComponents.connecting;
    };

    return (
        <div className="connection-prep">
            <div className="connection-status">
                {renderConnectionStatus()}
            </div>
        </div>
    );
};

export default ConnectionPrep; 