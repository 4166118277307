import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Navbar from '../components/Navbar';
import './TeacherHome.css';

const TeacherHome = () => {
  const navigate = useNavigate();
  const [roomId, setRoomId] = useState('');
  const [error, setError] = useState('');

  const createRoom = () => {
    const roomId = uuidv4().substring(0, 4);
    navigate(`/prep`, {
      state: {
        roomId: roomId,
        type: 'create-room'
      }
    });
  };

  const joinRoom = (e: React.FormEvent) => {
    e.preventDefault();
    if (!roomId.trim()) {
      setError('请输入房间号');
      return;
    }
    // if (!/^[0-9a-f-]{36}$/.test(roomId)) {
    //   setError('房间号格式不正确');
    //   return;
    // }
    navigate(`/prep`, {
      state: {
        roomId: roomId,
        type: 'join-room'
      } 
    });
  };

  return (
    <>
      <Navbar />
      <div className="home-container">
        <h1>视频聊天室</h1>
        <div className="action-container">
          <button onClick={createRoom} className="create-room-btn">
            创建新房间
          </button>
          <div className="divider">
            <span>或</span>
          </div>
          <form onSubmit={joinRoom} className="join-room-form">
            <input
              type="text"
              value={roomId}
              onChange={(e) => {
                setError('');
                setRoomId(e.target.value);
              }}
              placeholder="输入房间号"
              className="room-id-input"
            />
            {error && <div className="error-message">{error}</div>}
            <button type="submit" className="join-room-btn">
              加入房间
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default TeacherHome; 