import { Middleware } from '@reduxjs/toolkit';
import config from '../../config/config';
import { websocketSlice } from '../slices/websocketSlice';

let socket: WebSocket | null = null;
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;

const websocketMiddleware: Middleware = store => next => (action: any) => {
  if (action.type === websocketSlice.actions.connect.type) {
    if (socket?.readyState === WebSocket.OPEN) {
      return next(action);
    }

    store.dispatch(websocketSlice.actions.setStatus('connecting'));
    
    socket = new WebSocket(config.WEBSOCKET_URL_HTTPS);

    socket.onopen = () => {
      console.info('WebSocket opened');
      store.dispatch(websocketSlice.actions.setStatus('connected'));
      
      if (action.payload) {
        socket?.send(JSON.stringify(action.payload));
      }
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.info('WebSocket onmessage:', {
          type: data.type,
          payload: data,
          timestamp: new Date().toISOString()
        });
        
        if (data.type === 'ice-candidate' && data.candidate) {
          console.info('ICE Candidate Details:', {
            candidateType: data.candidate.candidate.split(' ')[7],
            protocol: data.candidate.candidate.split(' ')[2],
            address: data.candidate.candidate.split(' ')[4],
            port: data.candidate.candidate.split(' ')[5],
            timestamp: new Date().toISOString()
          });
        }

        reconnectAttempts = 0;
        store.dispatch(websocketSlice.actions.addMessage({
          type: data.type,
          payload: data,
          timestamp: new Date().toISOString()
        }));
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    socket.onclose = (event) => {
      console.log('WebSocket closed:', event);
      store.dispatch(websocketSlice.actions.setStatus('disconnected'));
      if (event.code !== 1000) {
        attemptReconnect(store.dispatch, action.payload);
      }
    };

    socket.onerror = (error) => {
      console.log('WebSocket connection error:', error);
      store.dispatch(websocketSlice.actions.setError('WebSocket connection error'));
      store.dispatch(websocketSlice.actions.setStatus('failed'));
    };
  }

  else if (action.type === websocketSlice.actions.disconnect.type) {
    if (socket) {
      socket.close();
      socket = null;
      reconnectAttempts = 0;
      store.dispatch(websocketSlice.actions.setStatus('disconnected'));
    }
  }

  else if (action.type === websocketSlice.actions.sendMessage.type) {
    if (socket?.readyState === WebSocket.OPEN) {
      console.info('WebSocket sendMessage:', {
        type: action.payload.type,
        data: action.payload,
        timestamp: new Date().toISOString()
      });
      
      socket.send(JSON.stringify(action.payload));
      if (action.payload.type === 'chat') {
        store.dispatch(websocketSlice.actions.addMessage({
          type: action.payload.type,
          payload: action.payload,
          timestamp: new Date().toISOString()
        }));
      }
    } else {
      console.warn('WebSocket Connection Status:', {
        readyState: socket?.readyState,
        timestamp: new Date().toISOString()
      });
      store.dispatch(websocketSlice.actions.setError('WebSocket is not connected'));
    }
  }

  return next(action);
};

const attemptReconnect = (dispatch: any, lastPayload: any) => {
  console.log('attemptReconnect' + reconnectAttempts);
  if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    reconnectAttempts++;
    setTimeout(() => {
      dispatch(websocketSlice.actions.connect(lastPayload));
    }, 3000);
  }
};

export default websocketMiddleware;
