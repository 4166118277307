import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebSocketState, WebSocketStatus, WebSocketMessage } from '../../types/websocket';

const initialState: WebSocketState = {
  status: 'disconnected',
  messages: [],
  error: null,
  roomId: null,
  userId: null
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<WebSocketStatus>) => {
      state.status = action.payload;
    },
    addMessage: (state, action: PayloadAction<WebSocketMessage>) => {
      state.messages.push(action.payload);
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setRoomInfo: (state, action: PayloadAction<{ roomId: string; userId: string }>) => {
      state.roomId = action.payload.roomId;
      state.userId = action.payload.userId;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    reset: () => initialState,
    connect: (state, action: PayloadAction<{
      type: string;
      roomId: string;
      userId: string;
      [key: string]: any;
    }>) => {},
    disconnect: (state) => {},
    sendMessage: (state, action: PayloadAction<{
      type: string;
      roomId: string;
      userId: string;
      [key: string]: any;
    }>) => {}
  }
});

export const { 
  setStatus, 
  addMessage, 
  setError, 
  setRoomInfo,
  clearMessages,
  reset,
  connect,
  disconnect,
  sendMessage
} = websocketSlice.actions;

export default websocketSlice.reducer;