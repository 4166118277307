import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import './Recharge.css';
import api from '../utils/ApiClient';
import { useAppSelector } from '../store/hooks';

const Recharge = () => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState<number | string>('');
    const [error, setError] = useState('');
    const { userId } = useAppSelector(state => state.user);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setAmount(value);
        }
    };

    const handleRecharge = () => {
        if (!amount || parseFloat(amount as string) <= 0) {
            setError('请输入有效的充值金额');
            return;
        }

        console.log(amount);
        setError('');
        api.post('/recharge/create', {
            userId: userId,
            amount: amount
        }).then(res => {
            console.log(res);
            // window.open.docu(res.data, '_blank');
            const newWindow = window.open();
            if (newWindow) {
                newWindow.document.open();
                newWindow.document.write(res.data);
                newWindow.document.close();
            }
            //打开一个form新页面
        }).catch(err => {
            console.log(err);
        });
        // const paymentUrl = `http://your-payment-url.com?amount=${amount}`;
        // navigate('/profile'); // 支付后返回到个人信息页面
    };

    return (
        <>
            <Navbar />
            <div className="recharge-container">
                <div className="recharge-card">
                    <h2>充值页面</h2>
                    {error && <div className="error-message">{error}</div>}

                    <div className="form-group">
                        <label>充值金额（元）</label>
                        <input
                            type="text"
                            placeholder="请输入充值金额"
                            value={amount}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="button-group">
                        <button className="recharge-button" onClick={handleRecharge}>
                            确认充值
                        </button>
                        <button className="cancel-button" onClick={() => navigate('/profile')}>
                            取消
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Recharge;
