interface Config {
  API_URL: string;
  SSE_URL: string;
  WEBSOCKET_URL_HTTP: string;
  WEBSOCKET_URL_HTTPS: string;
}

const config: Config = {
  API_URL: process.env.REACT_APP_API_URL || 'https://localhost:8090/api',
  SSE_URL: process.env.REACT_APP_SSE_URL || 'https://localhost:8090/api/stream/teacher',
  WEBSOCKET_URL_HTTP: process.env.REACT_APP_WEBSOCKET_URL_HTTP || 'ws://localhost:8091/signal',
  WEBSOCKET_URL_HTTPS: process.env.REACT_APP_WEBSOCKET_URL_HTTPS || 'wss://localhost:8091/signal',
};

console.log('Environment Variables', {
  API_URL: process.env.REACT_APP_API_URL,
  SSE_URL: process.env.REACT_APP_SSE_URL,
  WEBSOCKET_URL_HTTP: process.env.REACT_APP_WEBSOCKET_URL_HTTP,
  WEBSOCKET_URL_HTTPS: process.env.REACT_APP_WEBSOCKET_URL_HTTPS,
});

export default config; 