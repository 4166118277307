import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import api from '../utils/ApiClient';
import './UserProfile.css';
import { useAppSelector } from '../store/hooks';

interface UserProfile {
  email: string;
  username: string;
  lastName: string;
  firstName: string;
  avatar: string;
  userType: string;
  remainingMinutes: number;
}

const UserProfile = () => {
  const navigate = useNavigate();
  const { userId } = useAppSelector(state => state.user);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<UserProfile | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      const response = await api.get(`/users/${userId}`);
      setProfile(response.data);
      setFormData(response.data);
    } catch (error) {
      setError('获取用户信息失败');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData) return;

    try {
      await api.put(`/users/${userId}`, formData);
      setProfile(formData);
      setIsEditing(false);
      setError('');
    } catch (error) {
      setError('更新用户信息失败');
    }
  };

  const getUserTypeLabel = (type: string) => {
    const types = {
      'UT01': '管理员',
      'UT02': '教师',
      'UT03': '学生'
    };
    return types[type as keyof typeof types] || '未知';
  };

  if (!profile) {
    return <div className="loading">加载中...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="profile-container">
        <div className="profile-card">
          <h2>个人信息</h2>
          {error && <div className="error-message">{error}</div>}
          
          <div className="avatar-section">
            <img 
              src={profile.avatar || 'https://api.dicebear.com/7.x/avataaars/svg?seed=1'} 
              alt="用户头像" 
              className="profile-avatar" 
            />
          </div>

          {isEditing ? (
            <form onSubmit={handleSubmit} className="profile-form">
              <div className="form-group">
                <label>邮箱</label>
                <input
                  type="email"
                  name="email"
                  value={formData?.email || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>用户名</label>
                <input
                  type="text"
                  name="username"
                  value={formData?.username || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>姓</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>名</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName || ''}
                  onChange={handleInputChange}
                />
              </div>

              <div className="button-group">
                <button type="submit" className="save-button">保存</button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => {
                    setIsEditing(false);
                    setFormData(profile);
                  }}
                >
                  取消
                </button>
              </div>
            </form>
          ) : (
            <div className="profile-info">
              <div className="info-group">
                <label>邮箱</label>
                <span>{profile.email || '未设置'}</span>
              </div>

              <div className="info-group">
                <label>用户名</label>
                <span>{profile.username}</span>
              </div>

              <div className="info-group">
                <label>姓名</label>
                <span>{`${profile.lastName || ''} ${profile.firstName || ''}`}</span>
              </div>

              <div className="info-group">
                <label>用户类型</label>
                <span>{getUserTypeLabel(profile.userType)}</span>
              </div>

              {profile.userType === 'UT03' && (
                <div className="info-group">
                  <label>剩余时间</label>
                  <div className="time-info">
                    <span>{Math.floor(profile.remainingMinutes /60)}小时 {profile.remainingMinutes % 60}分钟</span>
                    <button 
                      className="recharge-button"
                      onClick={() => navigate('/recharge')}
                    >
                      充值
                    </button>
                  </div>
                </div>
              )}

              <button 
                className="edit-button"
                onClick={() => setIsEditing(true)}
              >
                编辑资料
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile; 