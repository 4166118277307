import { Middleware } from '@reduxjs/toolkit';
import { webrtcSlice } from '../slices/webrtcSlice';
import { sendMessage } from '../slices/websocketSlice';

let peerConnection: RTCPeerConnection | null = null;

const configuration = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:stun1.l.google.com:19302' },
    { urls: 'stun:stun2.l.google.com:19302' },
    { urls: 'stun:stun3.l.google.com:19302' },
    { urls: 'stun:stun4.l.google.com:19302' },
    // TURN 服务器
    {
      urls: 'turn:47.99.78.235:3478',  // 使用 turn: 前缀
      username: 'root',               // 你在 turnserver.conf 中配置的用户名
      credential: '123456'          // 你在 turnserver.conf 中配置的密码
    }
  ],
  iceCandidatePoolSize: 10
};

const webrtcMiddleware: Middleware = store => next => async (action: any) => {
  console.log('WebRTC Middleware received action:', action);
  const dispatch = store.dispatch;

  if (action.type === webrtcSlice.actions.initialize.type) {
    try {
      // Setup local stream
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 1280 }, height: { ideal: 720 }, facingMode: 'user' },
        audio: { echoCancellation: true, noiseSuppression: true }
      });
      
      dispatch(webrtcSlice.actions.setLocalStream(stream));
      
      // Setup peer connection
      peerConnection = new RTCPeerConnection(configuration);
      
      // Add tracks to peer connection
      stream.getTracks().forEach(track => {
        peerConnection?.addTrack(track, stream);
      });

      // Handle ICE candidates
      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          const { roomId, userId } = action.payload;
          dispatch(sendMessage({
            type: 'ice-candidate',
            roomId,
            userId,
            candidate: event.candidate.toJSON()
          }));
        }
      };

      // Handle remote stream
      peerConnection.ontrack = (event) => {
        console.log('Remote track received:', {
            kind: event.track.kind,
            streams: event.streams.length,
            streamId: event.streams[0]?.id
        });
        dispatch(webrtcSlice.actions.setRemoteStream(event.streams[0]));
      };

      dispatch(webrtcSlice.actions.setStatus('connected'));
    } catch (error) {
      dispatch(webrtcSlice.actions.setError((error as Error).message));
      dispatch(webrtcSlice.actions.setStatus('failed'));
    }
  } else if (action.type === webrtcSlice.actions.createOffer.type) {
      try {
          if (!peerConnection) {
              console.error('No peer connection available');
              return next(action);
          }

          console.log('Creating offer...');
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);

          const { roomId, userId } = action.payload;
          dispatch(sendMessage({
              type: 'offer',
              roomId,
              userId,
              offer
          }));

          console.log('Offer created and sent:', offer);
      } catch (error) {
          console.error('Error creating offer:', error);
          dispatch(webrtcSlice.actions.setError((error as Error).message));
      }
  } else if (action.type === webrtcSlice.actions.handleOffer.type) {
      try {
          if (!peerConnection) {
              console.error('No peer connection available');
              return next(action);
          }

          const { roomId, userId, offer } = action.payload;
          console.log('Handling offer:', offer);

          await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
          console.log('Remote description set');

          const answer = await peerConnection.createAnswer();
          await peerConnection.setLocalDescription(answer);

          dispatch(sendMessage({
              type: 'answer',
              roomId,
              userId,
              answer
          }));

      } catch (error) {
          console.error('Error handling offer:', error);
          dispatch(webrtcSlice.actions.setError((error as Error).message));
      }
  } else if (action.type === webrtcSlice.actions.handleAnswer.type) {
      try {
          if (!peerConnection) {
              console.error('No peer connection available');
              return next(action);
          }

          const { roomId, userId, answer } = action.payload;
          console.log('Handling answer:', answer);

          await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
          console.log('Remote description set');

      } catch (error) {
          console.error('Error handling answer:', error);
          dispatch(webrtcSlice.actions.setError((error as Error).message));
      }
  } else if (action.type === webrtcSlice.actions.handleIceCandidate.type) {
      try {
          if (!peerConnection) {
              console.error('No peer connection available');
              return next(action);
          }

          const { roomId, userId, candidate } = action.payload;
          console.log('Handling ICE candidate:', candidate);

          await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
          console.log('ICE candidate added successfully');

      } catch (error) {
          console.error('Error handling ICE candidate:', error);
          dispatch(webrtcSlice.actions.setError((error as Error).message));
      }
  } else if (action.type === webrtcSlice.actions.cleanup.type) {
      try {
          console.log('Cleaning up WebRTC connection');

          if (peerConnection) {
              peerConnection.close();
              peerConnection = null;
          }

          dispatch(webrtcSlice.actions.setLocalStream(null));
          dispatch(webrtcSlice.actions.setRemoteStream(null));
          dispatch(webrtcSlice.actions.setStatus('disconnected'));

      } catch (error) {
          console.error('Error during cleanup:', error);
          dispatch(webrtcSlice.actions.setError((error as Error).message));
      }
  }

  // 添加 ICE 连接状态监听
  if (peerConnection) {
    peerConnection.oniceconnectionstatechange = () => {
      const state = peerConnection?.iceConnectionState;
      console.log('[ICE诊断] ICE连接状态变化:', {
        状态: state,
        时间戳: new Date().toISOString()
      });
      
      switch(state) {
        case 'checking':
          console.log('[ICE诊断] 正在尝试建立连接...');
          break;
        case 'connected':
          console.log('[ICE诊断] 连接成功建立');
          break;
        case 'failed':
          console.error('[ICE诊断] 连接失败 - 可能需要 TURN 服务器');
          break;
        case 'disconnected':
          console.warn('[ICE诊断] 连接断开 - 尝试重新连接');
          break;
      }
    };

    // 添加连接状态监听
    peerConnection.onconnectionstatechange = () => {
      console.log('[连接诊断] 连接状态变化:', {
        状态: peerConnection?.connectionState,
        时间戳: new Date().toISOString()
      });
    };

    // 添加协商状态监听
    peerConnection.onnegotiationneeded = () => {
      console.log('[协商诊断] 需要重新协商:', {
        时间戳: new Date().toISOString()
      });
    };

    // 添加 ICE 候选错误处理
    peerConnection.onicecandidateerror = ((event: RTCPeerConnectionIceErrorEvent) => {
      console.error('[ICE诊断] ICE候选项错误:', {
        错误类型: event.errorCode,
        错误文本: event.errorText,
        主机地址: event.address,
        时间戳: new Date().toISOString()
      });
    }) as EventListener;
  }
  return next(action);
};

export default webrtcMiddleware; 