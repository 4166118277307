import axios from 'axios';
import config from '../config/config';

const api = axios.create({
    baseURL: config.API_URL,
});

// 请求拦截器：添加 token
api.interceptors.request.use(
    async (config: any) => {
        const token = await localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        console.log('Full Request URL:', `${config.baseURL}${config.url}`);
        console.log('Full Request Headers:', config.headers.Authorization);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器：处理 token 过期
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log('响应拦截器错误:', error);
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            console.log('Token 过期，尝试刷新');
            originalRequest._retry = true;

            const isConfirmed = window.confirm('登录已过期，请重新登录');
            await localStorage.removeItem('token');
            if (isConfirmed) {
                window.location.href = "/login";
            }
            return Promise.reject(error);

            // 刷新token
            // try {
            //     const newToken = await handleTokenRefresh();
            //     if (newToken) {
            //         originalRequest.headers.Authorization = `Bearer ${newToken}`;
            //         return api(originalRequest);
            //     }
            // } catch (refreshError) {
            //     await localStorage.removeItem('token');
            //     await localStorage.removeItem('refreshToken');
            //     navigate("/login");
            //     return Promise.reject(refreshError);
            // }
        }

        return Promise.reject(error);
    }
);

export default api; 