import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  token: string | null;
  userId: string | null;
  userType: string | null;
  username: string | null;
  isAuthenticated: boolean;
}

const initialState: UserState = {
  token: localStorage.getItem('token'),
  userId: localStorage.getItem('userId'),
  userType: localStorage.getItem('userType'),
  username: localStorage.getItem('username'),
  isAuthenticated: !!localStorage.getItem('token'),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{
      token: string;
      userId: string;
      userType: string;
      username: string;
    }>) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.userType = action.payload.userType;
      state.username = action.payload.username;
      state.isAuthenticated = true;
      
      // 同时更新 localStorage
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('userId', action.payload.userId);
      localStorage.setItem('userType', action.payload.userType);
      localStorage.setItem('username', action.payload.username);
    },
    logout: (state) => {
      state.token = null;
      state.userId = null;
      state.userType = null;
      state.username = null;
      state.isAuthenticated = false;
      
      // 清除 localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userType');
      localStorage.removeItem('username');
    },
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;
