import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logout } from '../store/slices/userSlice';

const Navbar = () => {
  const dispatch = useAppDispatch();
  const { userType, username } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span className="brand-text">视频课堂</span>
      </div>
      <div className="navbar-menu">
        <span 
          className="username" 
          onClick={() => navigate('/profile')}
          style={{ cursor: 'pointer' }}
        >
          欢迎，{username}
        </span>
        <div className="nav-buttons">
          <button 
            className="nav-link"
            onClick={() => navigate(userType === 'UT02' ? '/teacher' : '/')}
          >
            首页
          </button>
          <button 
            className="nav-link"
            onClick={handleLogout}
          >
            退出登录
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 