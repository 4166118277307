import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/StudentHome';
import Room from './pages/Room';
import ConnectionPrep from './pages/ConnectionPrep';
import TeacherHome from './pages/TeacherHome';
import Login from './pages/Login';
import Register from './pages/Register';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfile from './pages/UserProfile';
import Recharge from './pages/Recharge';
import PaySuccess from './pages/PaySuccess';
// 创建一个包装组件来使用 useLocation
function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* 保护的路由 */}
      <Route path="/" element={
          <Home />
      } />
      <Route path="/prep" element={
        <ProtectedRoute>
          <ConnectionPrep />
        </ProtectedRoute>
      } />
      <Route path="/room/:roomId" element={
        <ProtectedRoute>
          <Room />
        </ProtectedRoute>
      } />
      <Route path="/teacher" element={
        <ProtectedRoute>
          <TeacherHome />
        </ProtectedRoute>
      } />
      <Route path="/profile" element={
        <ProtectedRoute>
          <UserProfile />
        </ProtectedRoute>
      } />
      <Route path="/recharge" element={
        <ProtectedRoute>
          <Recharge />
        </ProtectedRoute>
      } />
      <Route path="/paysuccess" element={
        <ProtectedRoute>
          <PaySuccess />
        </ProtectedRoute>
      } />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
