import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import api from '../utils/ApiClient';
import { useAppDispatch } from '../store/hooks';
import { setUser } from '../store/slices/userSlice';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    // Basic validation
    if (!username || !password) {
      alert('请填写所有字段');
      return;
    }

    try {
      const response = await api.post('/login', {
        username,
        password,
      });

      if (response.status === 200) {
        const { token, userId, userType, username } = response.data;
        console.log("response.data:", response.data);
        // 使用 Redux 存储用户信息
        dispatch(setUser({
          token,
          userId: userId.toString(),
          userType,
          username,
        }));

        // alert('登录成功');
        if (userType === 'UT02') {
          navigate('/teacher');
        } else {
          navigate('/');
        }
      } else {
        alert(response.data.message || '请检查用户名和密码');
      }
    } catch (error) {
      alert('登录请求失败，请稍后重试');
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h1 className="title">登录</h1>
        
        <input
          className="input"
          type="text"
          placeholder="用户名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoCapitalize="none"
        />
        
        <input
          className="input"
          type="password"
          placeholder="密码"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        
        <button
          className="login-button"
          onClick={handleLogin}
        >
          登录
        </button>

        <button
          className="register-link"
          onClick={() => navigate('/register')}
        >
          没有账号？立即注册
        </button>
      </div>
    </div>
  );
}

export default Login;
