import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';
import api from '../utils/ApiClient';
import './StudentHome.css';
import Navbar from '../components/Navbar';

interface Teacher {
  id: string;
  username: string;
  isOnline: boolean;
  avatar: string;
  roomId: string;
}

const StudentHome = () => {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [loading, setLoading] = useState(true);
  //生成一个userId
  const userId = Math.random().toString(36).substring(2, 15); 

  const joinRoom = (roomId: string) => {
    navigate(`/prep`, {
      state: {
        roomId: roomId,
        type: 'join-room'
      }
    });
  }

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        console.log('Fetching teachers from URL:', `${config.API_URL}/teachers`);
        const response = await api.get<Teacher[]>('/teachers');
        console.log('Teachers API Response:', response);
        setTeachers(response.data);
      } catch (error) {
        console.error('Failed to fetch teachers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  useEffect(() => {
    if (loading) return;

    console.log('Connecting to SSE URL:', config.SSE_URL);
    const eventSource = new EventSource(`${config.SSE_URL}`);

    eventSource.onmessage = (event) => {
      console.log("Received SSE message:", event.data);
      const data : Teacher = JSON.parse(event.data);
      setTeachers(prevTeachers => {
        console.log("prevTeachers:", prevTeachers);
        const updatedTeachers = prevTeachers.map(teacher => {
          if (teacher.id === data.id) {
            return {
              ...teacher,
              roomId: data.roomId,
              isOnline: data.isOnline
            };
          }
          return teacher;
        });

        if (data.isOnline) {
          const onlineTeacherIndex = updatedTeachers.findIndex(
            teacher => teacher.id === data.id
          );
          if (onlineTeacherIndex > -1) {
            const [onlineTeacher] = updatedTeachers.splice(onlineTeacherIndex, 1);
            updatedTeachers.unshift(onlineTeacher);
          }
        }

        console.log("updatedTeachers:", updatedTeachers);
        return updatedTeachers;
      });
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      console.log('EventSource readyState:', eventSource.readyState);
      eventSource.close();
    };

    return () => {
      console.log('Closing SSE connection');
      eventSource.close();
    };
  }, [loading]);

  if (loading) {
    return <div className="loading">加载中...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="teacher-list">
        <div className="teacher-grid">
          {teachers.map((teacher) => (
            <div
              key={teacher.id}
              className="teacher-card"
              onClick={() => joinRoom(teacher.roomId)}
            >
              {teacher.isOnline && <div className="online-indicator" />}
              <div className="avatar-container">
                <img src={teacher.avatar} alt={teacher.username} className="avatar" />
              </div>
              <div className="teacher-name">{teacher.username}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StudentHome; 