import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Toast from '../components/Toast';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { connect, disconnect, sendMessage } from '../store/slices/websocketSlice';
import { 
  initialize, 
  cleanup, 
  createOffer, 
  handleOffer, 
  handleAnswer, 
  handleIceCandidate 
} from '../store/slices/webrtcSlice';
import './Room.css';

const Room = () => {
    const { roomId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const type = queryParams.get('type');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
        
    // UI states
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [isChatExpanded, setIsChatExpanded] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    
    // Refs
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);
    const pcRef = useRef<RTCPeerConnection | null>(null);
    
    // Redux states
    const { status: wsStatus, messages: wsMessages } = useAppSelector(state => state.websocket);
    const { status: webrtcStatus, localStream, remoteStream } = useAppSelector(state => state.webrtc);

    // WebRTC configuration
    const configuration = useMemo(() => ({
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
        ],
        iceCandidatePoolSize: 10
    }), []);

    // 添加调试日志函数
    const logEvent = (event: string, data?: any) => {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] ${event}`, data || '');
    };

    // 初始化 WebRTC 当 websocket 连接成功时
    useEffect(() => {
        if (wsStatus === 'connected' && webrtcStatus !== 'connected') {
            console.log('initialize');
            dispatch(initialize({
                roomId: roomId || '',
                userId: userId || ''
            }));

            // 添加连接状态监听
            if (pcRef.current) {
                pcRef.current.onconnectionstatechange = () => {
                    logEvent('Connection state changed:', pcRef.current?.connectionState);
                };

                pcRef.current.oniceconnectionstatechange = () => {
                    logEvent('ICE connection state changed:', pcRef.current?.iceConnectionState);
                };
            }
        }
    }, [wsStatus]);

    // 创建 offer 当 webrtc 连接成功时
    useEffect(() => {
        if (webrtcStatus === 'connected') {
            dispatch(createOffer({ 
                roomId: roomId || '', 
                userId: userId || '' 
            }));
        }
    }, [webrtcStatus]);

    // 清理 WebRTC 当 websocket 连接断开时
    useEffect(() => {
        return () => {
            dispatch(cleanup());
        };
    }, []);

    // Handle WebSocket messages
    useEffect(() => {
        if (!wsMessages.length) return;
        
        const lastMessage = wsMessages[wsMessages.length - 1];
        if (!lastMessage) return;

        logEvent('WebSocket message received', {
            type: lastMessage.type,
            payload: lastMessage.payload
        });

        switch (lastMessage.type) {
            case 'offer':
                console.log('Received offer:', lastMessage.payload.offer);
                dispatch(handleOffer({
                    roomId: roomId || '',
                    userId: userId || '',
                    offer: lastMessage.payload.offer
                }));
                break;
            case 'answer':
                console.log('Received answer:', lastMessage.payload.answer);
                dispatch(handleAnswer({
                    roomId: roomId || '',
                    userId: userId || '',
                    answer: lastMessage.payload.answer
                }));
                break;
            case 'ice-candidate':
                console.log('Received ICE candidate:', lastMessage.payload.candidate);
                dispatch(handleIceCandidate({
                    roomId: roomId || '',
                    userId: userId || '',
                    candidate: lastMessage.payload.candidate
                }));
                break;
            case 'force-leave-room':
                handleForceLeave(lastMessage.payload.message);
                break;
            case 'chat':
                if (lastMessage.payload.userId !== userId) {
                    handleChatMessage(lastMessage);
                }
                break;
        }
    }, [wsMessages]);

    // Handle media errors
    const handleMediaError = (error: Error) => {
        let message = '设备访问错误';
        if (error.name === 'NotAllowedError') {
            message = '请允许访问摄像头和麦克风';
        } else if (error.name === 'NotFoundError') {
            message = '未找到摄像头或麦克风设备';
        }
        setToastMessage(message);
        setShowToast(true);
    };

    // Initiate call with debug logs
    const initiateCall = async () => {
        logEvent('Initiating call');
        try {
            if (!pcRef.current) {
                logEvent('No peer connection available');
                return;
            }
            const offer = await pcRef.current.createOffer();
            logEvent('Offer created', offer);
            
            await pcRef.current.setLocalDescription(offer);
            logEvent('Local description set');
            
            dispatch(sendMessage({
                type: 'offer',
                roomId: roomId || '',
                userId: userId || '',
                offer
            }));
        } catch (err) {
            logEvent('Error initiating call', err);
        }
    };

    // Toggle video/audio
    const toggleVideo = () => {
        if (localStream) {
            localStream.getVideoTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            setIsVideoEnabled(!isVideoEnabled);
        }
    };

    const toggleAudio = () => {
        if (localStream) {
            localStream.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled;
            });
            setIsAudioEnabled(!isAudioEnabled);
        }
    };

    // Handle chat messages
    const handleChatMessage = (message: any) => {
        setMessages(prev => [...prev, {
            text: message.payload.message,
            self: false,
            userId: message.payload.userId,
            timestamp: message.timestamp
        }]);
    };

    const handleMessageSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!inputMessage.trim()) return;

        dispatch(sendMessage({
            type: 'chat',
            roomId: roomId || '',
            userId: userId || '',
            message: inputMessage
        }));

        setMessages(prev => [...prev, {
            text: inputMessage,
            self: true,
            timestamp: new Date().toISOString()
        }]);

        setInputMessage('');
    };

    // Handle force leave
    const handleForceLeave = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            navigate('/');
        }, 3000);
    };

    useEffect(() => {
        if (localStream && localVideoRef.current) {
            localVideoRef.current.srcObject = localStream;
        }
    }, [localStream]);

    useEffect(() => {
        if (remoteStream && remoteVideoRef.current) {
            console.log('[DEBUG] Setting remote stream:', {
                tracks: remoteStream.getTracks().map(track => ({
                    kind: track.kind,
                    enabled: track.enabled,
                    muted: track.muted,
                    readyState: track.readyState,
                    constraints: track.getConstraints(),
                    settings: track.getSettings()
                }))
            });
            remoteVideoRef.current.srcObject = remoteStream;
            
            // 检查视频元素的计算样式
            const computedStyle = window.getComputedStyle(remoteVideoRef.current);
            console.log('[DEBUG] Remote video element styles:', {
                width: computedStyle.width,
                height: computedStyle.height,
                display: computedStyle.display,
                visibility: computedStyle.visibility,
                opacity: computedStyle.opacity,
                zIndex: computedStyle.zIndex,
                position: computedStyle.position
            });
        }
    }, [remoteStream]);

    // 添加视频元素样式检查
    useEffect(() => {
        if (remoteVideoRef.current) {
            const videoElement = remoteVideoRef.current;
            const styles = window.getComputedStyle(videoElement);
            logEvent('Video element styles', {
                width: styles.width,
                height: styles.height,
                display: styles.display,
                visibility: styles.visibility,
                opacity: styles.opacity
            });
        }
    }, []);

    useEffect(() => {
        if (remoteStream) {
            logEvent('Remote stream received', {
                tracks: remoteStream.getTracks().map(track => ({
                    kind: track.kind,
                    enabled: track.enabled,
                    readyState: track.readyState
                }))
            });
        }
    }, [remoteStream]);

    // Render component
    return (
        <div className="video-chat">
            <button 
                className="back-button" 
                onClick={() => {
                    navigate('/');
                }}
            >
                返回首页
            </button>

            <div className="video-container">
                <div className="video-wrapper">
                    <video
                        ref={localVideoRef}
                        autoPlay
                        playsInline
                        muted
                        className="local-video"
                    />
                    <div className="controls">
                        <button 
                            onClick={toggleVideo}
                            className={isVideoEnabled ? 'active' : 'inactive'}
                        >
                            {isVideoEnabled ? '关闭摄像头' : '开启摄像头'}
                        </button>
                        <button 
                            onClick={toggleAudio}
                            className={isAudioEnabled ? 'active' : 'inactive'}
                        >
                            {isAudioEnabled ? '关闭麦克风' : '开启麦克风'}
                        </button>
                        <button 
                            onClick={() => setIsChatExpanded(!isChatExpanded)}
                            className={isChatExpanded ? 'active' : ''}
                        >
                            {isChatExpanded ? '收起聊天' : '展开聊天'}
                        </button>
                    </div>
                </div>
                <div className="remote-video-wrapper">
                    <video
                        ref={remoteVideoRef}
                        autoPlay
                        playsInline
                        className="remote-video"
                        style={{ border: '1px solid red' }}
                        onLoadedMetadata={(e) => {
                            console.log('[DEBUG] Remote video loadedmetadata:', {
                                videoWidth: e.currentTarget.videoWidth,
                                videoHeight: e.currentTarget.videoHeight,
                                readyState: e.currentTarget.readyState,
                                paused: e.currentTarget.paused,
                                currentSrc: e.currentTarget.currentSrc,
                                srcObject: e.currentTarget.srcObject
                            });
                            
                            const videoTrack = (remoteVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'video');
                                
                            console.log('[DEBUG] Video track details:', {
                                enabled: videoTrack?.enabled,
                                muted: videoTrack?.muted,
                                readyState: videoTrack?.readyState,
                                id: videoTrack?.id,
                                label: videoTrack?.label,
                                settings: videoTrack?.getSettings()
                            });

                            // Force play attempt
                            e.currentTarget.play().catch(err => {
                                console.error('[DEBUG] Auto-play failed:', err);
                            });
                        }}
                        onPlay={() => {
                            console.log('[DEBUG] Remote video started playing');
                            // 检查视频轨道状态
                            const videoTrack = (remoteVideoRef.current?.srcObject as MediaStream)
                                ?.getTracks()
                                .find(track => track.kind === 'video');
                            console.log('[DEBUG] Video track status:', {
                                enabled: videoTrack?.enabled,
                                muted: videoTrack?.muted,
                                readyState: videoTrack?.readyState
                            });
                        }}
                        onWaiting={() => console.log('[DEBUG] Remote video waiting')}
                        onStalled={() => console.log('[DEBUG] Remote video stalled')}
                        onSuspend={() => console.log('[DEBUG] Remote video suspended')}
                        onError={(e) => console.error('[DEBUG] Remote video error:', e)}
                    />
                    {!remoteStream && <div className="no-stream">等待远程视频连接...</div>}
                </div>
            </div>

            <div className={`chat-sidebar ${isChatExpanded ? 'expanded' : 'collapsed'}`}>
                <button 
                    className="mobile-close-chat"
                    onClick={() => setIsChatExpanded(false)}
                >
                    收起聊天
                </button>
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div 
                            key={index} 
                            className={`message ${msg.self ? 'self' : 'other'}`}
                        >
                            <div className="message-header">
                                {!msg.self && <span className="user-id">用户 {msg.userId}</span>}
                                <span className="timestamp">
                                    {new Date(msg.timestamp).toLocaleTimeString()}
                                </span>
                            </div>
                            <div className="message-text">{msg.text}</div>
                        </div>
                    ))}
                </div>
                <form className="message-input" onSubmit={handleMessageSubmit}>
                    <input
                        type="text"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="输入消息..."
                    />
                    <button type="submit">发送</button>
                </form>
            </div>

            {showToast && (
                <Toast 
                    message={toastMessage}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );
};

export default Room;